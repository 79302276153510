import { readCookie } from "./readCookie";

const epsilonRefreshCookieName = window?.epsilonConfig?.cookieName;
const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

/**
 * Validates what action we have to take for Epsilon
 * Could be:
 *  - none
 *  - get
 *  - refresh
 */
export function checkEpsilon() {
  // eslint-disable-next-line eqeqeq
  if (window.adConfig.userId == 0) {
    return {
      action: "none",
    } as const;
  }

  const refreshTS = readCookie(epsilonRefreshCookieName);

  // check if we need to refresh
  if (refreshTS && Date.now() > parseInt(refreshTS, 10)) {
    return {
      action: "refresh",
    } as const;
  }
  if (refreshTS) {
    return {
      action: "none",
    } as const;
  }

  return {
    action: "get",
  } as const;
}

/**
 * Takes the BE response, and decodes the Epsilon data, storing it in applicable cookies
 * @param response Our response from the BE
 */
export function decodeEpsilon(response: unknown) {
  const date = new Date();
  const expireTS = date.getTime() + 365 * MILLISECONDS_PER_DAY; // Milliseconds

  date.setTime(expireTS);
  const expires = `; expires=${date.toGMTString()}`;

  if (response.pubcid) {
    document.cookie = `_pubcid=${response.pubcid}${expires}; path=/`;
  }

  if (response.publink) {
    document.cookie = `_publink_srv=${response.publink}${expires}; path=/`;
  }

  if (response.refresh) {
    document.cookie = `${epsilonRefreshCookieName}=${response.refresh}${expires}; path=/`;
  }
}
