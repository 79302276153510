import { readCookie } from "./readCookie";

const { liverampConfig } = window;
const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
const MILLISECONDS_PER_MIN = 60 * 1000;

/**
 * Validates what action we have to take for Liveramp
 * Could be:
 *  - none
 *  - get
 *  - refresh
 *  - return-cookie
 */
export function checkLiveramp() {
  // eslint-disable-next-line eqeqeq
  if (window.adConfig.userId == 0) {
    return {
      action: "none",
    } as const;
  }

  const cookie = readCookie(liverampConfig.liverampCookieName);

  // Check usprivacy if opt out
  const ccpa = readCookie("usprivacy");
  if (ccpa && ccpa[2] === "Y") {
    // Delete cookies if exists
    if (cookie) {
      liverampDeleteCookies(liverampConfig.liverampCookieName);
      liverampDeleteCookies(liverampConfig.liverampPAIRCookieName);
    }
    return {
      action: "none",
    } as const;
  }

  // if cookie exists
  if (cookie) {
    const expCookieTs = readCookie(`${liverampConfig.liverampCookieName}_exp`); // Milliseconds

    // check that the cookie not expired, by comparing to TS stored in exp cookie, return the cookie if so
    if (Date.now() < parseInt(expCookieTs, 10)) {
      const refreshCookieTs = readCookie(
        `${liverampConfig.liverampCookieName}_refresh`
      ); // Milliseconds

      // refresh the env if 30 mins has passed
      if (Date.now() > parseInt(refreshCookieTs, 10)) {
        return {
          action: "refresh",
          cookie,
        } as const;
      }

      return {
        action: "return-cookie",
        cookie,
      } as const;
    }
  }

  return {
    action: "get",
  } as const;
}

/**
 * Takes the BE response, and decodes the Liveramp data, storing it in applicable cookies
 * @param response Our response from the BE
 */
type LiveRampEnvelope = {
  source: string;
  value: string;
};
export function decodeLiveramp(response: { envelopes: [LiveRampEnvelope] }) {
  const envelopeString = response.envelopes;

  if (Array.isArray(envelopeString)) {
    // base64 encode the entire JSON
    let base64Liverampenvelope = "";
    let base64PAIRenvelope = "";
    envelopeString.forEach((envelope: { source: string; value: string }) => {
      if (envelope.source === "envelopeLiveramp") {
        const envelopeLiveramp = { envelope: envelope.value };
        base64Liverampenvelope = window.btoa(JSON.stringify(envelopeLiveramp));
      } else if (envelope.source === "pairIds") {
        const encodedPAIRID = {
          envelope: JSON.parse(window.atob(envelope.value)),
        };
        base64PAIRenvelope = window.btoa(JSON.stringify(encodedPAIRID));
      }
    });
    if (base64Liverampenvelope) {
      liverampCreateCookie(
        liverampConfig.liverampCookieName,
        base64Liverampenvelope,
        liverampConfig.liverampCookieDuration
      );
    }
    if (base64PAIRenvelope) {
      liverampCreateCookie(
        liverampConfig.liverampPAIRCookieName,
        base64PAIRenvelope,
        liverampConfig.liverampPAIRCookieDuration
      );
    }
  }
}

/**
 * Takes the BE response, and decodes the Liveramp data, storing it in applicable cookies
 * @param name The (prefix) name of the cookie
 * @param value The value that will be set in the cookie
 * @param days The number of days before this cookie expires
 */
function liverampCreateCookie(name: string, value: string, days: number) {
  const date = new Date();
  const expireTS = date.getTime() + days * MILLISECONDS_PER_DAY; // Milliseconds
  const refreshTS = date.getTime() + 30 * MILLISECONDS_PER_MIN; // Milliseconds

  date.setTime(expireTS);
  const expires = `; expires=${date.toGMTString()}`;

  // create liveramp cookie
  document.cookie = `${name}=${value}${expires}; path=/`;

  // create liveramp refresh cookie
  document.cookie = `${name}_refresh=${refreshTS}${expires}; path=/`;

  // create liveramp expire ts cookie
  document.cookie = `${name}_exp=${expireTS}${expires}; path=/`;
}

/**
 * Deletes all the LiveRamp cookies
 * @param name The (prefix) name of the cookie
 * @param value The value that will be set in the cookie
 * @param days The number of days before this cookie expires
 */
function liverampDeleteCookies(name: string) {
  const date = new Date();

  date.setTime(date.getTime() - 86000);
  const expires = `; expires=${date.toGMTString()}`;

  // create liveramp cookie
  document.cookie = `${name}=${expires}; path=/`;

  // create liveramp refresh cookie
  document.cookie = `${name}_refresh=${expires}; path=/`;

  // create liveramp expire ts cookie
  document.cookie = `${name}_exp=${expires}; path=/`;
}
