/**
 * @param name The name of the cookie we are trying to read
 * @returns the contents of the specified cookie
 */
export function readCookie(name: string): string | null {
  const nameEQ = `${name}=`;
  const ca = document.cookie.replace(/ /g, "").split(";");
  let cValue: string | null = null;

  ca.forEach((entry) => {
    if (entry.indexOf(nameEQ) === 0) {
      cValue = entry.substring(nameEQ.length, entry.length);
    }
  });

  return cValue;
}
