import { XF } from "./XF";
import { checkEpsilon, decodeEpsilon } from "./epsilon";
import { checkLiveramp, decodeLiveramp } from "./liveramp";
import { readCookie } from "./readCookie";
import { checkTradedesk, decodeTradedesk } from "./tradedesk";

function retrieveLiveRampEnvelope() {
  const cookie = readCookie(window.liverampConfig.liverampCookieName);

  return formatEnvelope(cookie);
}

/**
 * The function that will be exposed to Liveramp's window.ats.retrieveEnvelope function
 * @param cookie The cookie data, in base64
 */
function formatEnvelope(cookie: string) {
  // Verify that we have a cookie
  if (cookie == null) {
    return undefined;
  }

  // Parse the cookie data
  const envelopeJson = JSON.parse(window.atob(cookie));
  return envelopeJson || undefined;
}

function initIdentity() {
  const query = {};

  // if live ramp is enabled
  if (window?.liverampConfig) {
    // Exposes the LiveRamp envelope data to a global function for retrieval from ad providers
    window.ats = {};
    window.ats.retrieveEnvelope = retrieveLiveRampEnvelope;

    // Determine if we need to call the BE
    const runLiveRamp = checkLiveramp();

    // Call the BE?

    if (runLiveRamp.action !== "none") {
      if (runLiveRamp.action === "refresh") {
        try {
          query.liveramp = {
            action: runLiveRamp.action,
            envelope: JSON.parse(window.atob(runLiveRamp.cookie)).envelope,
          };
        } catch (err) {
          // TODO: cookie should be validated/parsed upstream instead of in this specific case
          console.error("Failed to parse liveramp cookie", err);
        }
      } else if (runLiveRamp.action === "get") {
        query.liveramp = {
          action: runLiveRamp.action,
        };
      }
    }
  }

  // if epsilon is enabled
  if (window.epsilonConfig) {
    const runEpsilon = checkEpsilon();
    if (runEpsilon.action !== "none") {
      if (runEpsilon.action === "refresh") {
        query.epsilon = {
          action: "refresh",
        };
      } else if (runEpsilon.action === "get") {
        query.epsilon = {
          action: "get",
        };
      }
    }
  }

  // if trade desk is enabled
  if (window?.tradedeskConfig) {
    const runTradedesk = checkTradedesk();

    if (runTradedesk.action !== "none") {
      if (runTradedesk.action === "refresh") {
        query.tradedesk = {
          action: "refresh",
          refresh_token: runTradedesk.refresh_token,
          refresh_response_key: runTradedesk.refresh_response_key,
        };
      } else if (runTradedesk.action === "get") {
        query.tradedesk = {
          action: "get",
        };
      }
    }
  }

  if (JSON.stringify(query) === "{}") {
    return;
  }

  // Call the BE to get relevant data
  getIdentity(query);
}

/**
 * This function calls the BE with the payload data, expecting identity solution data to be returned
 * @param query The payload that will be sent to the BE
 */
function getIdentity(query: string) {
  const endpoint = `/account/identity?${encodeURIComponent(
    JSON.stringify(query)
  )}`;
  const request = new XMLHttpRequest();

  request.open("GET", endpoint);
  request.setRequestHeader(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  request.onreadystatechange = function onReadyStateChange() {
    if (this.readyState === 4) {
      const response = JSON.parse(this.response);

      if (response.liveramp) {
        decodeLiveramp(response.liveramp);
      }

      if (response.epsilon) {
        decodeEpsilon(response.epsilon);
      }

      if (response.tradedesk) {
        decodeTradedesk(response.tradedesk);
      }
    }
  };

  request.send(`_xfResponseType=json&_xfToken=${XF.config.csrf}`);
}

// Start the process
initIdentity();
